import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import useError404Settings from "../hooks/error404";
import { useHTMLProcessor } from "../hooks/htmlProcessor";

export default function NotFoundPage() {
  const { processPageContent } = useHTMLProcessor();
  const { errordescription, errorsummary, errortitle } = useError404Settings();
  let { content: processedContent } = processPageContent(errordescription);

  return (
    <Layout>
      <SEO title={errortitle} />
      <article className="c-article o-margin-top-large">
        <div className="o-grid">
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit">
              <h1 className="c-article__title">{errortitle}</h1>
            </div>
          </div>
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit">
              <div className="preamble">{errorsummary}</div>
            </div>
          </div>
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit c-article">
              {processedContent}
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
}
